import Modal from "antd/es/modal/Modal";
import crosscircleicon from '../../assets/images/crosscirlce.png'
import { Checkbox } from "antd";
import Button from 'antd/es/button';
import { useState, useEffect } from "react";
import { pauseMebershipApi } from "../../api/customerapi";

const PauseMemberShipModal = ({ pauseModal, setPauseModal, membershipData, setPopUp, setCheckedList, checkedList }) => {
  useEffect(() => {
    setCheckedList([]);
  }, [])
  const onChangeList = (e, item) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, item._id]);
    }
    else {
      const filterval = checkedList.filter((val) => val != item._id)
      setCheckedList(filterval);
    }
  };
  return (
    <>
      <Modal
        centered
        width={550}
        height={400}
        header={null}
        footer={null}
        className="pausemembershipmodal"
        closable={false}
        open={pauseModal}
      >
        <div className="pausememberheading">Select the membership you want to pause?
          <span className="crossicon" onClick={() => { setPauseModal(false) }} ><img src={crosscircleicon} /></span></div>
        <div className="membershipdetails">
          {membershipData.map((item, index) => {
            return (
                <div className="membershipinfo" key={index}>
                  <div className="membershiptitle"> {item.title}</div>
                                 { (new Date() > new Date(item.pauseTill) && new Date() < new Date(item.subscriptionEnd))&&item.hasCanceled==0?<Checkbox className="Membershipheading" onChange={(e) => { onChangeList(e, item) }} />:<Checkbox className="Membershipheading" disabled={true}/>}
                </div>
            )
          })}
        </div>
        <div className="pauseMemBtn">
          <Button
            className={!checkedList.length > 0 ? "pausedisabledbtn" : "pausebtn"}
            onClick={() => {
              setPauseModal(false);
              setPopUp(true);
            }}
          >
            Pause Membership
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default PauseMemberShipModal;