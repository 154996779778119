import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import whiterednotification from '../assets/settingicons/Notification.svg'
import Filter from '../assets/images/Filter.svg'
import BusinessLogo from '../assets/settingicons/BusinessLogo.svg'
import searchIcon from '../assets/settingicons/searchIcon.svg'
import { Input } from 'antd';
import { setSearchString } from '../store/loginslice/subscribeCusSlice'
import { showFilterModal } from '../store/loginslice/mermberShipSlice';
import blackrednotification from '../assets/images/notificationicon.svg';
import NotificationCard from './notification/NotificationCard';
import { getNotificationapi, readNotificationApi } from '../api/notificationapi'
import './Navbar.less';
import filterblack from '../assets/images/Filter_Filled.svg'
import crosscircleicon from '../assets/images/crosscirlce.png'
import { useOutsideAlerter } from '../utils/outsideClick'
import filterred from '../assets/images/filterred.svg';
import { Spin } from 'antd'
import whitenotifcation from '../assets/images/nonotification.svg'
import nonotificationblack from '../assets/images/nonotifcationblack.svg';
import { setIsRead } from '../store/loginslice/LoginSlice';
import NotificationList from './NotificationList'

const Navbar = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const pathname = location.pathname.split('/')[1];
  const searchString = useSelector(state => state.subscribeCustomer?.searchString)
  const filterModal = useSelector(state => state.memberShip?.filterModal)
  const userData = useSelector(state => state.user?.user);
  const isFiltered = useSelector(state => state?.memberShip?.isFiltered);
  const isNotRead = useSelector(state => state?.user?.user?.isRead);
  const wrapperRef = useRef();
  const listInnerRef = useRef();
  const dispatch = useDispatch();
  const filterSearch = (e) => {
    dispatch(setSearchString(e.target.value))
  }
  const closeModal = () => {
    setOpenNotification(false)
    setPage(1)
    dispatch(setIsRead(false))
  }
  const callNotification = async () => {
    setOpenNotification(prev => !prev)
    callNotApi();
  }
  const callNotApi = async () => {
    setLoading(true)
    const resp = await getNotificationapi(page);
    setLoading(false)
    if (resp.status === 200) {
      if (page == 1) {
        setNotificationList(resp.data.output.outputData.list);
        setResultCount(resp.data.output.outputData.resultCount);
      }
      else {
        setNotificationList([...notificationList, ...resp.data.output.outputData.list]);
      }

      const notificationIds = resp.data.output.outputData.list.map((item) => {
        if (item.isRead == false) {
          return item._id;
        }
      })
      const notIds = notificationIds.filter((item) => item != null);
      if (notIds.length > 0) {
        const resp = await readNotificationApi(notIds);
      }
    }
  }
  useEffect(() => {
    if (page != 1) {
      callNotApi();
    }
  }, [page])
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (notificationList.length < resultCount) {
          setPage((prev) => prev + 1);
        }
      }
    }
  };
  useOutsideAlerter(wrapperRef, openNotification, closeModal);

  return (
    <div className="navbar-container">
      <div className='navbar-wrapper'>
        <div className='heading'>{pathname?.split('-')?.join(' ')}</div>
        {pathname === 'subscribed-customers' && (
          <div className="search-wrapper">
            <img src={searchIcon} className="searchicon" />
            <Input placeholder="Search Customer" className="search-input" value={searchString} onChange={filterSearch} />
          </div>
        )}
        <div className='actions'>
          {/* <NotificationList callNotification={callNotification} openNotification={openNotification} isNotRead={isNotRead} wrapperRef={wrapperRef} loading={loading} notificationList={notificationList} onScroll={onScroll} listInnerRef={listInnerRef} /> */}
          <div className='notification-wrapper' onClick={() => { callNotification() }}>
            {openNotification ? (isNotRead ? <img src={blackrednotification} alt="Notification" /> : <img src={nonotificationblack} alt="Notification" />) : (isNotRead ? <img src={whiterednotification} alt="Notification" /> : <img src={whitenotifcation} alt="Notification" />)}
            {openNotification &&

              <div className="notificationcard" ref={wrapperRef}>
                <img className="closeimg" src={crosscircleicon} onClick={() => { dispatch(setIsRead(false)) }} />
                <Spin spinning={loading}>
                  <div className="heading ">{notificationList?.length > 0 ? "Notifications" : "No Notifications"}</div>

                  {notificationList.length > 0 &&
                    <div className="notificationlist" onScroll={onScroll} ref={listInnerRef} >
                      {
                        notificationList.map((item) => {
                          return (
                            <NotificationCard item={item} />
                          )
                        }

                        )
                      }

                    </div>}
                </Spin>
              </div>}
          </div>
          {pathname === 'membership-packages' && (
            <>
              {!openNotification ?
                !filterModal ? <div className='filter-wrapper'>
                  <img src={!isFiltered ? Filter : filterred} alt="Filter" onClick={() => dispatch(showFilterModal(!filterModal))} />
                </div> : <div className='filter-wrapper'>
                  <img src={filterblack} alt="Filter" onClick={() => dispatch(showFilterModal(!filterModal))} />
                  <div className="triangle"></div>
                </div>
                :
                <div className='filter-wrapper' onClick={() => { setOpenNotification(false); dispatch(showFilterModal(!filterModal)) }}>
                  <img src={!isFiltered ? Filter : filterred} alt="Filter" /></div>}
            </>

          )}
          <div className='logo'>
            <img src={userData?.logo || BusinessLogo} alt="logo" /> <div className='businessName'>{userData?.businessName}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;