import React, { useState, useRef, useEffect } from "react";
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';
import AddressCard from "../../components/AddressCard";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../common/helper";
import symbolImg from '../../assets/images/locationsymbol.png'
import '../../pages/Signup/signup.less';
import { List } from "antd";

const BusinessLocation = ({ save, incProgress, isEditProfile, setAddressValue, setAddressModal, setSavedata }) => {
  const [disabled, setDisabled] = useState(true);
  const [address, setAddress] = useState({})
  const [addLocArr, setAddLocArr] = useState([])
  const [value, setValue] = useState("");
  const [showList, setShowList] = useState(false)

  const formRef = useRef();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "ca" },
    },
  });

  useEffect(() => {
    handleFormChange();
  }, [address])

  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid)
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components)
        setValue(placeDetails?.name)
        formRef.current.setFieldValue('address', placeDetails?.name);
        formRef.current.setFieldValue('city', address?.city);
        formRef.current.setFieldValue('province', address?.region);
        formRef.current.setFieldValue('postalCode', address?.postal_code);
        setAddress({
          address: placeDetails?.name,
          city: address?.city
          , province: address?.region
          , postalCode: address?.postal_code, lat: placeDetails.geometry.location.lat().toString(), lon: placeDetails.geometry.location.lng().toString()
        })
        setShowList(false)
      }
    );
  }

  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      value => value
    );
    const hasErrors = !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length)
        .length > 0;
    setDisabled(hasErrors);
    if (!hasErrors && !isEditProfile) {
      incProgress(100)
    }
  }

  const handleClick = async () => {
    let addValLocation = addLocArr;
    if (address.address && address.city && address.province && address.postalCode) {
      addValLocation = [...addLocArr, address]
    }
    if (isEditProfile) {
      setAddressValue(addValLocation)
      setAddressModal(false)
    }
    else {
      setDisabled(true)
      save({ businessAddress: addValLocation })
      setSavedata(true)
    }
  }

  const handleEdit = (val) => {
    const output = addLocArr.filter((value) => value.address !== val.address);
    setDisabled(false)
    setAddLocArr(output);
    formRef.current.setFieldValue('address', val?.address);
    formRef.current.setFieldValue('city', val?.city);
    formRef.current.setFieldValue('province', val?.province);
    formRef.current.setFieldValue('postalCode', val?.postalCode);
  }

  const handleAddAnotherLoc = () => {
    if (!disabled && address.address) {
      setAddLocArr([
        ...addLocArr, address
      ])
      setAddress({})
    }
    setDisabled(true)

    formRef.current.setFieldValue('address', '')
    formRef.current.setFieldValue('city', '');
    formRef.current.setFieldValue('province', '');
    formRef.current.setFieldValue('postalCode', '');
  }

  const [form] = Form.useForm();

  return (
    <>
      <div className="center-container " >
        <div className="welcome-container" style={{ marginTop: "1%" }}>
          <div className="center-container justify-content-center"  >
            {!isEditProfile &&
              (addLocArr.length <= 0 ?
                <h1 className="welcome-header locationname" >Where Are You Based?</h1> : <h1 className="welcome-header mb-0" >Add More Location</h1>)
            }
            {addLocArr.length > 0 &&
              <div className="addresscarddetails" >
                {addLocArr.map((val, index) => {
                  return (
                    <AddressCard handleEdit={handleEdit} val={val} key={index} />
                  )
                })}
              </div>
            }
            <Form
              initialValues={{ remember: true }}
              // onFinish={onFinish}
              ref={formRef}
              autoComplete="off"
              layout="vertical"
              onFieldsChange={handleFormChange}
              form={form}
              className={addLocArr.length <= 0 ? "locationform" : "locationform1"}
            >
              <div className={addLocArr.length <= 0 ? "addresdetails" : "addressdetail"}>
                <div style={{ color: "#6B6B6B" }} className="mb-2">Address</div>
                <Form.Item
                  name="address"
                  type="text"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your address!',
                    }
                  ]}
                >
                  <Input
                    size="large"
                    style={{ color: "black", height: "50px" }}
                    suffix={<img src={symbolImg} />}
                    value={value}
                    autoFocus
                    onChange={(evt) => {
                      setValue(evt.target.value)
                      formRef.current.setFieldValue('address', evt.target.value)
                      getPlacePredictions({ input: evt.target.value });
                      if (!isPlacePredictionsLoading) setShowList(true)
                    }}
                    onKeyDown={(event) => {
                      if (event?.keyCode === 8 || event?.keyCode === 46) {
                        form.setFieldValue("city", '')
                        form.setFieldValue("province", '')
                        form.setFieldValue("postalCode", '')
                        handleFormChange()
                      }
                    }}
                    loading={false}
                    placeholder="Enter address"
                  />
                  {value !== '' && showList && placePredictions.length > 0 && (
                    <div className="google-autosuggestion-list">
                      <List
                        dataSource={placePredictions}
                        renderItem={(item) => (
                          <List.Item onClick={() => getDetails(item?.place_id)}>
                            <List.Item.Meta title={item.description} />
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </Form.Item>
                <div style={{ color: "#6B6B6B" }} className="mb-2">City</div>
                <Form.Item
                  name="city"
                  className="mb-3"
                  rules={[
                    {
                      type: 'address',
                      message: 'Please enter a valid city',
                    }
                  ]}
                >
                  <Input size="large" placeholder="Enter city" className="input-field" onChange={(e) => { form.setFieldValue("city", e.target.value); setAddress({ ...address, city: e.target.value }) }} />
                </Form.Item>
                <div className="subform">
                  <div className="subform1">
                    <div style={{ color: "#6B6B6B" }} className="mb-2">Province</div>
                    <Form.Item
                      name="province"
                      className="mb-3"
                      rules={[
                        {
                          type: 'address',
                          message: 'Please enter a valid Province',
                        }

                      ]}
                    >
                      <Input size="large" placeholder="Enter province" className="input-field" onChange={(e) => { form.setFieldValue("province", e.target.value); setAddress({ ...address, province: e.target.value }) }} />
                    </Form.Item>
                  </div>
                  <div className="subform1">
                    <div style={{ color: "#6B6B6B" }} className="mb-2">Postal Code</div>
                    <Form.Item
                      name="postalCode"
                      className="mb-3"
                      rules={[
                        {
                          type: 'postal',
                          message: 'Please enter a valid postal code',
                        }
                      ]}
                    >
                      <Input size="large" placeholder="Enter postal code" className="input-field" onChange={(e) => { form.setFieldValue("postalCode", e.target.value), setAddress({ ...address, postalCode: e.target.value }) }} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Button
                style={{ height: "50px", marginTop: "1%", backgroundColor: "#fff", color: "#003B5B", fontWeight: "bold", border: "1px solid #003B5B", width: "100%" }}
                type="primary"
                size="large"
                className={disabled ? ' editsaved' : 'editsaved'}
                disabled={!disabled || addLocArr.length > 0 ? false : true}
                onClick={() => { handleAddAnotherLoc() }}
              >
                <span className="addloc">
                  Add Another Location
                </span>
              </Button>
              <Button
                // htmlType="submit"
                style={{ height: "50px", marginTop: "1%", width: "100%" }}
                className={!disabled || addLocArr.length > 0 ? 'loc_btn editsaved' : 'loc_btndisabled editsavedisabled'}
                disabled={!disabled || addLocArr.length > 0 ? false : true}
                type="primary"
                size="large"
                onClick={() => { handleClick() }}
              >
                {isEditProfile ? " Save" : "Next"}
              </Button>
            </Form>
          </div></div></div>
    </>
  )
}
BusinessLocation.propTypes = {
  setPageCount: PropTypes.func,
  save: PropTypes.func

}

export default BusinessLocation;