import HelpComp from "../../components/HelpComp";
import data from './data.js'

const Help = () => {
  return (
    <div className="settingcard helpcards">
     {data.map((item)=>{
      return(
       < HelpComp  question={item.question} answer={item.answer}/>
      )
     })}
    </div>
  )
}

export default Help;