import Modal from "antd/es/modal/Modal";
import Form from 'antd/es/form';
import circlecross from '../../assets/images/crosscirlce.png'
import { Carousel } from "antd";

const ShowMoreModal = ({ currentItem, showModal, setShowModal }) => {
  const [form] = Form.useForm();
  return (
    <>
      <Modal
        centered
        header={null}
        footer={null}
        open={showModal}
        className="newsfeedmodalshow"
        closable={false}
        style={{ top: "10px" }}
      >
        <div className="newsfeedcolmodal" >
          <img src={circlecross} className="crossiconimg" onClick={() => { setShowModal(false) }} />
          <div className="newsimg">
            <Carousel autoplay={true} effect={'fade'} autoplaySpeed={3000}>
              {currentItem?.media?.map((val, index) => {
                return (
                  <>
                    {val.type.slice(-3) != "mp4" ?
                      <div className="newsimage">
                        <img src={val.url} className="newsimageimg1" /></div>
                      : <div className="newsimageimg" >
                        <video controls  className="newsimageimg1">
                          <source src={val.url} type="video/webm" />
                          <source src={val.url} type="video/mp4" />
                        </video>
                      </div>

                    }
                  </>
                )
              })}
            </Carousel>
          </div>
          <div className="newfeedcontent">
            <div style={{ color: "#003B5B", position: "relative", bottom: '10px',fontSize:"20px",fontWeight:"500" }}>{currentItem.title}</div>
            <div style={{ color: "#6B6B6B", maxHeight: "200px", overflowY: "auto" }}>{currentItem.description}</div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ShowMoreModal;