import Modal from "antd/es/modal/Modal";
import './styles.less'
import crosscircleicon from '../../assets/images/crosscirlce.png'
import { Input, Button } from "antd";
import { useState, useEffect } from "react";
import { addBonusForCustomer } from '../../api/customerapi';
import { useSelector } from "react-redux";
import dummyprofile from '../../assets/images/dummyprofile.png';
import Form from "antd/es/form";
import {message} from 'antd';

const UpdateBonusModal = ({ addBonusModal, setAddBonusModal, updateModal, setUpdateModal, selectedId, viewCustomerApi, custInfo, amount }) => {
  const customerId = useSelector((state) => state.customer.customerId)
  const [bonusBal, setBonusBal] = useState(0);
  const [values, setValues] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [detailsd, setDetailsd] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    setValues({
      email: custInfo.email,
      amount: amount,
      firstName:custInfo.firstName,
      lastName:custInfo.lastName
    })
    const val=`$${amount}`;
    form.setFieldValue('amount', val);
    form.setFieldValue('email', custInfo.email)
    form.setFieldValue('firstName', custInfo.firstName)
    form.setFieldValue('lastName', custInfo.lastName)
  }, [custInfo])
  const handleFormChange = () => {
    const hasErrors =
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDetailsd(hasErrors);
  }
  
  const updateBonus = async () => {
    setAddBonusModal(false);
    setUpdateModal(true);
    const val=parseInt(amount)+parseInt(bonusBal);
    form.setFieldValue('amount', val);
    setValues({...values,amount:parseInt(amount)+parseInt(bonusBal)})
  }
  const callApi = async () => {
    if(bonusBal!=0)
    {
      const response = await addBonusForCustomer({ customerId: customerId || selectedId, amount: parseInt(bonusBal),email:values.email,firstName:values.firstName,lastName:values.lastName });
      if (response.data?.status === 200) {
        viewCustomerApi(customerId || selectedId);
      }
      else
      {
       message.error("Something went wrong please try again")
      }
    }
    else
    {
      const response = await addBonusForCustomer({ customerId: customerId || selectedId, email:values.email,firstName:values.firstName,lastName:values.lastName });
      if (response.data?.status === 200) {
        viewCustomerApi(customerId || selectedId);
      }
      else
      {
       message.error("Something went wrong please try again")
      }
    }
  }

  const handleChange = (e) => {
    if (e.target.value > 0) {
      setDisabled(false)
    }
    else {
      setDisabled(true)
    }
  }
  const handleAddBonus=()=>{
    setAddBonusModal(true); 
    setUpdateModal(false);
    setValues({...values,amount:parseInt(amount)+parseInt(bonusBal)})
  }

  return (
    <>
      <Modal
        centered
        width={450}
        header={null}
        footer={null}
        className="addbonusmodal"
        closable={false}
        open={addBonusModal}
      >
        <div className="crossicon" onClick={() => { setAddBonusModal(false) }}><img style={{width:"25px",height:"25px"}} src={crosscircleicon} /></div>
        <div className="addbonusheader" >Add Bonus
        </div>


        <div className="addbonusinputinfo">
          <div className="addbonusinput" style={{marginBottom:"8px"}}>Bonus Balance
          </div>
              <span className="searchicon" >$</span>
          <Input type="number"   style={{height:"45px",width:"100%"}} min={1} className="input-field" onChange={(e) => { setBonusBal(e.target.value); handleChange(e) }} />
        </div>
        <Button onClick={() => { updateBonus() }} className={!disabled ? "addbonusbtn" : "addbonusbtndisabled"} disabled={disabled}>Add Bonus</Button>
      </Modal>
      <Modal
        centered
        width={550}
        header={null}
        footer={null}
        className="updatedetailsmodal"
        closable={false}
        open={updateModal}
      >
          <div className="crossicon" onClick={() => { setUpdateModal(false) }}><img style={{width:"25px",height:"25px"}} src={crosscircleicon} /></div>
        <div className="updateinfoheader" >Customer Details
        </div>

        <div className="updateuserlogo d-flex m-auto">
          <img className="userimg"  src={custInfo.profilePicture ? custInfo.profilePicture : dummyprofile} />
        </div>
        <Form
          name="name-form"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          //  onFinish={onFinish}
          // validateTrigger='onSubmit'
          onFieldsChange={handleFormChange}
          form={form}
        >
          <div className="updatebalance">
            <div className="updateheader">  Total Balance
            </div>
            <Form.Item
             // name="amount"
              type="number"
              className="mb-3"
              rules={[
                {
                  required: true,
                  message: 'Please enter your amount',
                },
                {
                  pattern: new RegExp(/^[1-9][0-9]*$/),
                  message:
                    "It should be number and must not start with zero",
                },
              ]}
            >
              <Input type="text" disabled className="input-field" style={{color:"black"}}value={`$${values.amount}`} onChange={(e) => { setValues({ ...values, amount: e.target.value }) }} />
            </Form.Item>
            <Button className="addbonus" onClick={() => {handleAddBonus()  }}>Add Bonus</Button>
          </div>
          <div className="updatebalance">
            <div className="updateheader">First Name
            </div>
            <Form.Item
              name="firstName"
              type="text"
              className="mb-3"
              rules={[
                {
                  type: 'text',
                  message: 'Please enter a valid name',
                },
                {
                  required: true,
                  message: 'Please enter your name',
                },
              ]}
              initialValue={values.firstName}
            >
              <Input className="input-field" value={values.firstName} onChange={(e) => { setValues({ ...values, firstName: e.target.value }) }} />
            </Form.Item>
          </div>
          <div className="updatebalance">
            <div className="updateheader">Last Name
            </div>
            <Form.Item
              name="lastName"
              type="text"
              className="mb-3"
              rules={[
                {
                  type: 'text',
                  message: 'Please enter a valid name',
                },
                {
                  required: true,
                  message: 'Please enter your name',
                },
              ]}
              initialValue={values.lastName}
            >
              <Input className="input-field" value={values.lastName} onChange={(e) => { setValues({ ...values, lastName: e.target.value }) }} />
            </Form.Item>
          </div>
          <div className="updatebalance">
            <div className="updateheader">Email
            </div>
            <Form.Item
              name="email"
              type="email"
              className="mb-3"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid E-mail.',
                },
                {
                  required: true,
                  message: 'Please enter your E-mail',
                },
              ]}
            >
              <Input className="input-field" value={values.email} onChange={(e) => { setValues({ ...values, email: e.target.value }) }} />
            </Form.Item>.
          </div>
          <Button htmlType="submit" className="updatebtn" disabled={detailsd} onClick={() => { setUpdateModal(false); callApi() }}>Save</Button>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateBonusModal;