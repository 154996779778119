import Modal from "antd/es/modal/Modal";
import React, { useState } from "react";
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import imageicon from '../../assets/images/Imageicon.png'
import TextArea from "antd/es/input/TextArea";
import Button from 'antd/es/button';
import circlecross from '../../assets/images/crosscirlce.png'
import './newsfeed.less'
import { Spin } from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import { useEffect } from "react";
import infocircle from '../../assets/images/Info-Circle.png'
import Delete from '../../assets/settingicons/Delete.svg';
import circleplus from '../../assets/images/circleplus.png'
import SpinWrapper from '../../components/wrapper/SpinWrapper'
import { Tooltip } from "antd";

const AddModal = ({ catData, setImagevideoArr, handleAddClick, imagevideoArr, addModal, setAddModal, handleImageUpload, setValues, values, loading, checked, setChecked, isEdit, currentItem, handleDeleteNewsFeed, callgetListApi, setHandleDeleteOpen, handlePopScroll }) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (isEdit) {
      form.setFieldValue('title', currentItem?.title);
      form.setFieldValue('category', currentItem?.category?.name)
      form.setFieldValue('sendNotification', currentItem?.sendNotification)
      form.setFieldValue('description', currentItem?.description)
      setChecked(currentItem?.sendNotification)
      setImagevideoArr(currentItem?.media)
      setValues({ ...values, title: currentItem?.title, description: currentItem?.description, categoryId: currentItem?.categoryId, sendNotification: currentItem?.sendNotification, media: currentItem?.media, id: currentItem?._id, category: { name: currentItem?.category?.name } })
    }
    else {
      setValues({ ...values, media: [] });
      setChecked(false)
      setImagevideoArr([]);
    }
  }, [currentItem, isEdit])

  useEffect(() => {
    handleFormChange();
  }, [values.media])
  const handleD = () => {
    setAddModal(false)
    setHandleDeleteOpen(true)
  }
  const handleFormChange = () => {
    if (form.getFieldValue('title')?.length > 0 && form.getFieldValue('category')?.length > 0 && form.getFieldValue('description')?.length > 0 && (values?.media?.length > 0)) {
      setDisabled(false)
    }
    else {
      setDisabled(true)
    }
  }
  const handleImageEdit = (val) => {
    const output = values?.media?.filter((item) => item.url != val)
    setValues({ ...values, media: output })
  }
  const handleSelectChange = (value) => {
    const val = catData.filter((val) => val.name === value)
    setValues({ ...values, categoryId: val[0]._id, category: { name: val[0].name } })
  }
  const handleCheck = () => {
    setChecked((prev) => !prev);
    setValues({ ...values, sendNotification: checked });
  }
  return (
    <>
      <Modal
        centered
        width={600}
        header={null}
        footer={null}
        open={addModal}
        className="newsfeedmodal"
        closable={false}
      >
        <SpinWrapper>
          <div className="newfeedheader" style={{ textAlign: "center", marginBottom: "1%" }}>{isEdit ? "Edit" : "Add"} News Feed</div>
          <span style={{ position: "absolute", right: "-1vh", top: "-1vh", cursor: "pointer" }}>    <img style={{ width: "29px" }} src={circlecross} onClick={() => { setAddModal(false) }} /></span>
          <div className="addingnewsfeed">
            <Form
              name="name-form"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFieldsChange={handleFormChange}
              form={form}
            >
              <div className="scrollcontent" style={{ margin: "auto", overflowY: "scroll", height: "430px", overflowX: "hidden" }}>
                <div className="newsfeedheading">Select News Feed Category</div>
                <Form.Item
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your business category',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    showArrow
                    placeholder="Select Category"
                    className="input-field"
                    onChange={(e) => { handleSelectChange(e); }}
                    onPopupScroll={handlePopScroll}
                  >
                    {catData?.map((item, index) => {
                      return (
                        <Select.Option value={item.name} key={index}>
                          <div className="demo-option-label-item">{item.name}</div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <div className="newsfeedheading"> News Feed Title</div>
                <Form.Item
                  name="title"
                  className="mb-3"
                  rules={[
                    {
                      type: 'name',
                      message: 'Please enter a valid business Title',
                    },
                    {
                      required: true,
                      message: 'Please enter your business Title',
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z ]+$/),
                      message:
                        "Field does not accept numbers or special characters.",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Title" className="input-field" onChange={(e) => { setValues({ ...values, title: e.target.value }); }} />
                </Form.Item>
                <div className="newsfeedheading"> Upload Image/Video<span style={{ color: "#6B6B6B" }}>(Approx. Size 1080*1080)</span></div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

                  {values?.media?.length > 0 && values?.media?.map((item, index) => {
                    return (
                      <div key={index}>
                        {item?.type?.slice(-3) != "mp4" ?
                          <div className="newsimage">
                            <div className="editiconimg" onClick={() => { handleImageEdit(item.url) }} >
                              <img src={Delete} style={{ position: "absolute", left: "20px", top: "10px", cursor: "pointer" }} />
                            </div>
                            <div className="imageicon" style={{ width: "548px", height: "270px", overflow: "hidden", borderRadius: "10px", marginBottom: "20px" }}>
                              <img src={item.url} className="newsimageimg" style={{ width: "100%", height: "100%", borderRadius: "10px", objectFit: "cover" }} /></div></div>
                          : <div className="newsimage" >
                            <div className="editiconimg" onClick={() => { handleImageEdit(item.url) }} >
                              <img src={Delete} style={{ position: "absolute", left: "20px", top: "10px", zIndex: 1, cursor: "pointer" }} />
                            </div>
                            <video style={{ width: "548px", height: "270px", position: "relative", bottom: "10px", borderRadius: "10px", objectFit: "cover" }} >
                              <source src={item.url} type="video/webm" />
                              <source src={item} type="video/mp4" />
                              Sorry, your browser doesn't support videos.
                            </video>
                          </div>
                        }
                      </div>
                    )
                  })}
                </div>
                <Input accept="image/*,video/mp4" type="file" id="file" style={{ display: "none" }} onChange={async (val) => { await handleImageUpload(val); }} />
                <div className="imagebox" >
                  <div className="imageicon">
                    <img src={imageicon} />
                  </div>
                  <div> upload image/video</div>
                  <div ><label htmlFor="file" >
                    <img src={circleplus} style={{ width: "50px", height: "50px", cursor: "pointer" }} />
                  </label></div>
                </div>
                <div className="newsfeedheading"> News Feed Description</div>
                <Form.Item
                  name="description"
                  type="businessdescription"
                  className="mb-3"
                  rules={[
                    {
                      type: 'name',
                      message: 'Please enter a valid business description',
                    },
                    {
                      required: true,
                      message: 'Please enter your business description',
                    },
                  ]}
                >
                  <TextArea size="large" placeholder="Enter Business Description " className="input-field textareainput" style={{ height: "150px" }} onChange={(e) => { setValues({ ...values, description: e.target.value }); }} />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "space-between", color: '#111111', fontWeight: "600" }}><span> Send Push Notification (Optional)<span>
                  <Tooltip placement="bottomRight" title="If it is clicked then all members that are signed up with your business will get a push notification" arrow={false} overlayInnerStyle={{ color: "#6B6B6B", backgroundColor: "#fff", width: "20vw", fontSize: "12px", fontWeight: "400" }} overlayStyle={{
                    color: "#fff"
                  }}>
                    <img src={infocircle} style={{ width: "20px", height: "20px" }} />
                  </Tooltip>
                </span></span>
                  <span>
                    <Form.Item size="large" name="sendNotification" defaultValue={checked} >
                      <Checkbox className="d-flex addcheckbox" checked={checked} onClick={() => { handleCheck() }}>
                      </Checkbox>
                    </Form.Item>
                  </span>
                </div>
              </div>
              {!isEdit ? <Button
                htmlType="submit"
                style={{ height: "50px", marginTop: "3%" }}
                className={disabled ? 'continue_btn_disabled' : 'continue_btn'}
                disabled={disabled}
                type="primary"
                size="large"
                onClick={() => { setAddModal(false); handleAddClick() }}
              >
                Post
              </Button> :
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    htmlType="submit"
                    style={{ height: "50px", marginTop: "3%", width: "40%", backgroundColor: "#F1F1F1", color: "#000" }}
                    type="primary"
                    size="large"
                    onClick={() => {
                      handleD()
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    htmlType="submit"
                    style={{ height: "50px", marginTop: "3%", width: "40%" }}
                    className={disabled ? 'continue_btn_disabled' : 'continue_btn'}
                    type="primary"
                    size="large"
                    onClick={() => { handleAddClick() }}
                    disabled={disabled}
                  >
                    Save
                  </Button>
                </div>
              }
            </Form>
          </div>
        </SpinWrapper>
      </Modal>
    </>
  )
}
export default AddModal;