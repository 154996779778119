import React, { useState, useEffect } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Col, Row, message } from 'antd';
import { addCard, subscribe } from '../../api/subscribe'
import Spinner from '../../common/component/Spinner';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { setIsPaid } from "../../store/loginslice/LoginSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import SpinWrapper from '../../components/wrapper/SpinWrapper';
import { setLoader } from '../../store/loginslice/LoaderSlice';
import { makeDefaultCard } from '../../api/subscribe';


const SubscriptionForm = ({ showModal, cardAdding, setSuccess, setStripe, getAllCards, setShowModal, percentage, couponCode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true)
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  })
  const [checkfields, setCheckfields] = useState({
    cardnumber: false,
    expiry: false,
    cvv: false,
    name: false

  })

  useEffect(() => {
    if (elements) {
      elements?.getElement(CardNumberElement).clear();
      elements?.getElement(CardExpiryElement).clear();
      elements?.getElement(CardCvcElement).clear();
      form.resetFields()
    }
    return () => {
      console.log(showModal, 'showModal');
    }
  }, [])

  useEffect(() => {
    if (checkfields.cardnumber === "true" && checkfields.expiry === "true" && checkfields.cvv === "true" && checkfields.name === "true") {
      setDisabled(false)
    } else setDisabled(true)
  }, [checkfields])

  const changeFormData = (e) => {
    setFormData({
      ...formData, [e.target.name]: e.target.value
    });
  }

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

    if (checkfields.cardnumber && checkfields.expiry && checkfields.cvv && checkfields.name) {
      setDisabled(hasErrors);
    } else setDisabled(true)
  }

  const handleSubmit = async () => {
    try {
      if (elements == null) return;
      dispatch(setLoader(true))
      // setLoading(true);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        message.error(error.message)
        //  setLoading(false);
        dispatch(setLoader(false));
      }

      if (paymentMethod?.card?.funding !== 'credit' && paymentMethod?.card?.funding !== 'debit') {
        setShowModal(false);
        dispatch(setLoader(false));
        // setLoading(false);
        message.error('Only credit card or debit card allowed')
        return;
      }

      if (location.pathname !== '/subscription') {
        const payload = {
          name: formData?.name,
          brand: paymentMethod?.card?.brand,
          last4: paymentMethod?.card?.last4,
          funding: paymentMethod?.card.funding,
          expMonth: paymentMethod?.card?.exp_month?.toString(),
          expYear: paymentMethod?.card?.exp_year?.toString(),
          paymentMethodId: paymentMethod?.id,

        }
        const res = await addCard(payload);
        if (res.data?.status === 200) {
         makeDefaultCard({id:res.data.output.cardData._id})
          getAllCards();
          dispatch(setLoader(false));
          // setLoading(false);
          setShowModal(false);
          elements?.getElement(CardNumberElement).clear();
          elements?.getElement(CardExpiryElement).clear();
          elements?.getElement(CardCvcElement).clear();
          form.resetFields();
        }
      } else {
        const payload = {
          name: formData?.name,
          brand: paymentMethod?.card?.brand,
          last4: paymentMethod?.card?.last4,
          funding: paymentMethod?.card.funding,
          expMonth: paymentMethod?.card?.exp_month?.toString(),
          expYear: paymentMethod?.card?.exp_year?.toString(),
          paymentId: paymentMethod?.id,
          ...(couponCode ? { couponCode } : {})
        }
        const res = await subscribe(payload);

        if (res.data?.status === 200) {
          dispatch(setIsPaid(true));
          setStripe(false);
          dispatch(setLoader(false));
          //  setLoading(false);
          setSuccess(true);
        }
      }
    } catch (error) {
      if (error?.response?.data?.error?.message == 'Subscription Already existing') {
        dispatch(setIsPaid(true));
        navigate('/membership-packages')
      }
      // setLoading(false);
      dispatch(setLoader(false));
      message.error(error?.response?.data?.error?.message);
    }
  }

  const handleChange = async (event, cardinput) => {
    const e = await event;
    if (cardinput == "name") {
      setFormData({
        ...formData, [e.target.name]: e.target.value
      });
      if (e.target.value.length > 1) {
        setCheckfields({ ...checkfields, name: "true" })
      }
      else {
        setCheckfields({ ...checkfields, name: "false" })
      }

    }

    if (e?.complete === true) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "true" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "true" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "true" })
    } else if (e?.error) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "false" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "false" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "false" })
    } else if (e?.empty === false && e?.error === undefined) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "false" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "false" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "false" })
    }
  }

  return (
    <>
    <SpinWrapper>
      <div className="card-element-wrapper">
        {location.pathname === '/subscription' && <div className='pay-amount'>
          <div id='text'>Payable Amount</div>
          {percentage != '' ? <div id='amount'>${250 - 250 * percentage / 100}</div> : <div id='amount'>$250</div>}
        </div>}

        <Form
          onFieldsChange={handleFormChange}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div>

            <div className='label'>Name on card</div>
            <Form.Item
              name="name"
              rules={[
                {
                  type: 'name',
                  message: 'Please enter a valid card name',
                },
                {
                  required: true,
                  message: 'Please enter your card name',
                },
                {
                  pattern: new RegExp(/^[A-Za-z ]+$/),
                  message:
                    "Field does not accept numbers or special characters.",
                },
              ]}
            >
              <Input
                name="name"
                className="input-text-field"
                placeholder="Name on Card"
                type="text"
                // onChange={changeFormData}
                onChange={(e) => handleChange(e, "name")}
                value={formData?.name}
                pattern='[A-Za-z]'
                maxLength={32}
                minLength={3}
              />
            </Form.Item>
          </div>
          <div>
            <div className='label'>Credit Card Number</div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter your card number',
                }
              ]}
            >
              <CardNumberElement
                options={{
                  showIcon: true,
                  iconStyle: 'solid',
                  placeholder: 'Enter Credit Number',
                  style: {
                    empty: {
                      '::placeholder': {
                        color: '#6B6B6B',
                      },
                    },
                    base: {
                      iconColor: '#111111',
                      color: '#111111',
                      fontWeight: '500',
                      fontSize: '16px',
                      fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: '#111111',
                      },
                    },
                    invalid: {
                      iconColor: 'red',
                      color: 'red',
                      fontWeight: '500',
                      fontSize: '16px',
                      fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                    },
                  },
                }}
                className='card-number'
                onChange={(e) => handleChange(e, "cardnumber")}
              />
            </Form.Item>
          </div>
          <div>
            <Row className="card-element d-flex justify-content-between">
              <Col xs={24} md={11} lg={11} className='mb-sm-3 mb-md-0'>
                <div className='label'>Expiry Date</div>
                <Form.Item>
                  <CardExpiryElement
                    className='card-expiry'
                    options={{
                      placeholder: 'MM/YY',
                      style: {
                        empty: {
                          '::placeholder': {
                            color: '#6B6B6B',
                          },
                        },
                        base: {
                          iconColor: '#111111',
                          color: '#111111',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#111111',
                          },
                        },
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                        },
                      },
                    }}
                    onChange={(e) => handleChange(e, "expiry")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={11} lg={11} className='card-cvv-wrapper'>
                <div className='label'>CVV</div>
                <Form.Item>
                  <CardCvcElement
                    className='card-cvv'
                    type="password"
                    options={{
                      placeholder: 'Enter CVV',
                      style: {
                        empty: {
                          '::placeholder': {
                            color: '#6B6B6B',
                          },
                        },
                        base: {
                          iconColor: '#111111',
                          color: '#111111',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#111111',
                          },
                        },
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                        },
                      },
                    }}
                    onChange={(e) => handleChange(e, "cvv")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
     
      </div>
      <div className={disabled ? "pay-now-disabled" : "pay-now"} onClick={() => handleSubmit()}>
        {cardAdding ? "Save" : "Pay Now"}
      </div>
      </SpinWrapper>
      {/* <Spinner loading={loading} /> */}
    </>
  )
}

export default SubscriptionForm