// import { useState } from "react";

// const useNetworkStatus = (url = "https://api.github.com/", interval = 1500) => {
//   const [isOnline, setIsOnline] = useState(true);

//   setInterval(() => {
//     const controller = new AbortController();
//     // const signal = controller.signal;
//     // const promise = fetch(url, { signal, cache: "no-store", mode: "no-cors" });
//     // const timeout = setTimeout(() => controller.abort(), interval);
//     const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
//     if (connection && navigator.onLine) {
//       setIsOnline(true)
//     } else setIsOnline(false)
//   }, interval);

//   return isOnline;
// };

// export default useNetworkStatus;
import React, { useState, useEffect } from 'react';

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;
